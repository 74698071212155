<template>
    <v-col md="8" cols="12">
        <v-row>
            <v-col cols="12" class="pb-0">
                <h6>Participant Disclaimer</h6>
            </v-col>
            <v-col cols="12" class="pb-0">
                <a target="_blank" href="https://www.oneworldayurveda.com/program-participants-disclaimer/">Oneworld Ayurveda Participants Disclaimer</a>
            </v-col>
            <v-col cols="12" class="pt-0">
                <a target="_blank" href="https://www.oneworldayurveda.com/privacy-policy/">Oneworld Ayurveda Privacy Policy</a>
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-checkbox
                    v-model="checkbox"
                    label="I have read and agree to the Retreat Participants Disclaimer and Privacy Policy"
                ></v-checkbox>
                <span class="text-span">Note: You will be asked to sign the disclaimer upon arrival at the center.</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pb-0">
                <h6>Payment Information</h6>
                Voucher code (optional - valid discounts will be applied to your balance payment)
                <v-text-field
                    v-model="payload.voucher_code"
                    outlined
                    class="text-hidden"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn 
                    :disabled="!valid"
                    block
                    depressed
                    large
                    color="primary"
                    :loading="loading"
                    @click="paymentProcess(payload)"
                >
                    Submit
                </v-btn>

                <v-snackbar
                    v-model="snackbar"
                >
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="primary"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                        Close
                        </v-btn>
                    </template>
                </v-snackbar>

                <v-dialog
                    eager
                    max-width="500"
                    v-model="dialog"
                >
                    <v-card height="600">
                        <iframe 
                            v-if="iframeReady"
                            :src="iframe"
                            frameborder="0"
                        ></iframe>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import axios from 'axios'
export default {
    name: 'PCPaymentShow',
    props: {
        paymentData: Object,
        valid:Boolean,
        coba:Object
    },
    data () {
        return {
            checkbox: false,
            loading: false,
            snackbar: false,
            text: '',
            dialog: false,
            iframeReady: false,
            payload: this.paymentData,
            payment_methods: [
                {
                    show: 'Credit card',
                    code: ["CREDIT_CARD"]
                },
                {
                    show: 'Bank transfer',
                    code: ['bank']
                },
                {   
                    show: 'Do you still have question or would like more information?',
                    code: ['else']
                },
            ],
        }
    },
    methods: {
        async paymentProcess(payload) {
            try {
                this.coba.validate()   
            } catch (error) {
                this.text = 'Please complete all required fields before submitting the form'
                this.snackbar = true
            }
            if (this.coba.validate() != false && this.payload.payment != "" && this.checkbox == true && this.payload.price > 0) { 
                this.loading = true
                await axios.post('https://api.oneworldbali.com/owa/shoter-stay', payload).then(response => {
                // await axios.post('http://localhost:3344/owr/pc-payment', payload).then(response => {
                    if (response.status == 200 && response.data.status == 'DOKU') {
                        this.iframe = response.data.link+'?view=iframe'
                        this.dialog = true
                        this.iframeReady = true
                    } else {
                        window.location.replace(response.data.link)
                    }
                }).catch(error => {
                    console.log(error.response)
                });
                this.loading= false  
            } else if (this.coba.validate() != false && this.payload.payment == "") {
                this.text = 'You do not choose yet the payment method'
                this.snackbar = true
            } else if (this.checkbox == false) {
                this.text = 'You do not tick yet the program disclaimer'
                this.snackbar = true
            } else {
                this.text = 'Please complete all required fields before submitting the form'
                this.snackbar = true
            }
        }
    },
    computed: {
        currency() {
            return new Intl.NumberFormat('en-US').format(this.paymentData.price);
        }
    },
}
</script>

<style>

</style>